export const tr = (obj, locale) => {
  if (locale === 'en') {
    const o = {};
    Object.assign(o, obj);
    const keys = Object.keys(obj);
    for (const i in keys) {
      const k = keys[i]
      if (o[k] !== undefined && o[k + 'En'] !== undefined)
        o[k] = o[k + 'En'];
    }
    return o;
  } else {
    return obj;
  }
}

export const imop = (cloudinaryUrl, op) => {
  if (!cloudinaryUrl)
    return cloudinaryUrl
  return cloudinaryUrl.replace(/(https:\/\/res\.cloudinary\.com\/.+\/image\/upload\/)(.+)/, `$1${op}/$2`)
}